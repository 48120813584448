<script>
export default {
  name: "NewsCard",
  props: [
    'news'
  ],
}
</script>

<template>
    <div v-if="news" placeholder-glow>
        <div
            v-for="item in news" :key="item"
            class="alert alert-dismissible fade show" :class="item.type" role="alert">
            <h4 class="alert-heading">{{item.title}}</h4>
            <span v-html="item.text"/>
        </div>
    </div>
</template>

<style>

</style>
